<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteSafetyTopic" />
              <app-button-close route-name="safetyTopic_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <input type="hidden" v-model="safetyTopic.id">
                    <app-input
                      v-model="safetyTopic.title"
                      @blur="$v.safetyTopic.title.$touch()"
                      :error="$v.safetyTopic.title.$error"
                      id="safetyTopic_title"
                      :label="$t('safetyTopic.title')"
                    >
                    </app-input>
                    <app-input
                      v-model="safetyTopic.code"
                      @blur="$v.safetyTopic.code.$touch()"
                      :error="$v.safetyTopic.code.$error"
                      id="safetyTopic_code"
                      :label="$t('safetyTopic.code')"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import SafetyTopicModel from '../../model/SafetyTopic'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'SafetyTopicNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      safetyTopic: this._.cloneDeep(SafetyTopicModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SAFETY_TOPIC_PERMISSIONS
    }
  },
  validations: {
    safetyTopic: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45)
      },
      code: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose
  },
  computed: {
    isDeleteButtonVisible () {
      return this.safetyTopic?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('safetyTopic/create', this.safetyTopic)
          .then(() => {
            if (this.$store.getters['safetyTopic/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.safetyTopic = this.$store.getters['safetyTopic/detail']
              this.$router.push('/safetyTopic/' + this.safetyTopic.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['safetyTopic/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteSafetyTopic () {
      this.$store.dispatch('safetyTopic/deleteRecord', this.safetyTopic)
        .then(() => {
          if (this.$store.getters['safetyTopic/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/safetyTopic')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['safetyTopic/error'])
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>
